@import '../variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$secondary-color: map_get($base-colors, 'text-color');
$light_gray: map_get($font-colors, 'light-gray');
$dark_gray: map_get($font-colors, 'dark-gray');
$white: map_get($font-colors, 'white');
$alto: map_get($font-colors, 'alto');
$lightning-yellow: map_get($font-colors, 'lightning-yellow');

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .carousel-container {
    position: relative;
    display: flex;
    width: 100%;
  }

  .Carousel {
    min-height: 200px;
    position: relative;
  }

  .CarouselItem {
    width: 100%;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
    cursor: pointer;
  }

  .slide-container {
    position: relative;
    width: 100%;

    .slide-info-container {
      position: absolute;
      top: 13%;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
      z-index: 10;

      .heading {
        color: $lightning-yellow;
        font-weight: bold;
        letter-spacing: 2px;
        margin-top: 5%;

        @media only screen and (max-width: 800px) {
          top: 25%;
          text-align: center;
          font-size: 22px;
        }

        @media (min-width: 280px) and (max-width: 570px) {
          font-size: 15px;
        }
      }
    }
  }

  .carosel-image {
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  .carosel-title {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .carosel-description {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .carosel-join-button {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    border-color: #ffc43e;
    border-radius: 65px;
    font-size: 14px;
    text-transform: uppercase;
    &:hover {
      background-color: #ffc43e;
    }
  }

  .title-text {
    width: 100%;
    font-size: 4vw;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .description-text {
    color: #e9e9e9e8;
    margin-top: 10px;
  }

  .company-profile-container {
    min-height: 100px;
    margin-top: 80px;
    width: 100%;
    background-color: $primary-color;

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      height: 100%;
      margin: 0 auto;

      .title {
        color: $white;
        font-weight: 700;
        font-size: 22px;
        position: relative;
        padding-left: 10px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 100%;
          background-color: $secondary-color;
        }
      }

      a {
        text-decoration: none;
        color: $white;
        border: 2px solid $white;
        border-radius: 0;
        height: 50px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $secondary-color;
          border: none;
        }

        span {
          font-weight: bold;
        }
      }
    }

    @media (min-width: 250px) and (max-width: 480px) {
      margin-bottom: 80px;
    }
  }
}

@media print, screen and (min-width: 48em) {
  .Carousel {
    max-width: 1280px;
    position: relative;
    margin: 0 auto;
  }
}
