@import '../variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$secondary-color: map_get($base-colors, 'text-color');
$light_gray: map_get($font-colors, 'light-gray');
$dark_gray: map_get($font-colors, 'dark-gray');
$white: map_get($font-colors, 'white');
$alto: map_get($font-colors, 'alto');
$lightning-yellow: map_get($font-colors, 'lightning-yellow');

.categories {
  width: 80%;
  margin: 0 auto;

  h1 {
    margin-top: 80px;
    color: $primary-color;

    &:after {
      content: '';
      display: block;
      width: 10%;
      padding-top: 10px;
      border-bottom: 2px solid $secondary-color;
    }
  }

  .categories-grid-container {
    margin-top: -50px;

    .category-grid-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;

      .category-item {
        height: 290px;
        width: 310px;
        position: relative;
        overflow: hidden;
        border-bottom: 5px solid transparent;
        transition: 0.3s;
        border-radius: 8px;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.4);
        }

        &:hover {
          img {
            transform: scale(1.1);
          }

          .description-container {
            transform: translateY(-1em);
            bottom: 0;

            p {
              position: relative;
              opacity: 1;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          transition: 0.3s;
          border-radius: 8px;
        }

        .description-container {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 0.938em 1.25em;
          transition: all 0.3s ease;
          text-align: center;

          z-index: 1;
          box-sizing: border-box;

          .title {
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 2px;
            color: $white;
          }

          p {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #fdc312;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
            opacity: 0;
            transition: all 0.3s ease;
          }
        }
      }

      .brochure {
        padding: 8px;
        border-radius: 5px;
        font-size: 15px;
        text-decoration: none;
        margin-top: 15px;
        background-color: $secondary-color;
        color: $white;
        &:hover {
          background-color: $primary-color;
        }
      }

      .MuiButton-root {
        text-transform: capitalize;
      }
    }
  }
}
