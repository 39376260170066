@import '../variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$secondary-color: map_get($base-colors, 'text-color');
$light_gray: map_get($font-colors, 'light-gray');
$dark_gray: map_get($font-colors, 'dark-gray');
$white: map_get($font-colors, 'white');
$alto: map_get($font-colors, 'alto');
$lightning-yellow: map_get($font-colors, 'lightning-yellow');

.quote-section {
  background: url('../../assets/requestQuote.jpg') no-repeat scroll center 0;
  height: 550px;
  margin-top: 80px;
  width: 100%;
  background-size: 100% 100%;

  .container {
    height: 100%;

    .info-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1,
      p,
      Button {
        color: $white;
        text-align: center;
      }

      Button {
        background-color: $secondary-color;
        border: 2px solid $secondary-color;

        &:hover {
          background-color: $primary-color;
          border: 2px solid $secondary-color;
        }
      }
    }
  }
}
