@import '../variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$secondary-color: map_get($base-colors, 'text-color');
$light_gray: map_get($font-colors, 'light-gray');
$dark_gray: map_get($font-colors, 'dark-gray');
$white: map_get($font-colors, 'white');
$alto: map_get($font-colors, 'alto');
$lightning-yellow: map_get($font-colors, 'lightning-yellow');

.services {
  width: 80%;
  margin: 0 auto;

  .title {
    margin-top: 100px;
    font-weight: bold;
    color: $primary-color;
    text-transform: capitalize;

    &:after {
      content: '';
      display: block;
      width: 10%;
      padding-top: 10px;
      border-bottom: 2px solid $secondary-color;
    }
  }

  .service-container {
    width: 100%;
    margin: 0 auto;
  }

  .block {
    .container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      transition: all 0.3s ease;
      padding: 10px 25px 0 25px;

      .icon {
        line-height: 1.3;
        position: relative;
        margin-top: 20px;
        font-size: 40px;
        color: white;
        padding: 0.52em;
        font-size: 1.5rem;
        background-color: #fdc312;
        align-self: flex-start;
      }

      .info {
        padding-left: 15px;

        p {
          text-align: left;
          font-family: 'Work Sans', Arial, sans-serif;
          font-size: 16px;
          line-height: 1.8;
          font-weight: 400;
          color: #999999;
        }

        h2 {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.5;
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}
