@import '../variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$secondary-color: map_get($base-colors, 'text-color');
$light_gray: map_get($font-colors, 'light-gray');
$dark_gray: map_get($font-colors, 'dark-gray');
$white: map_get($font-colors, 'white');
$alto: map_get($font-colors, 'alto');
$lightning-yellow: map_get($font-colors, 'lightning-yellow');

.awards-section {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 100px;

  h1 {
    margin-top: 80px;
    color: $primary-color;

    &:after {
      content: '';
      display: block;
      width: 10%;
      padding-top: 10px;
      border-bottom: 2px solid $secondary-color;
    }
  }

  .awards-container {
    .award-grid-item {
      margin-top: 20px;
      img {
        width: 100%;
        height: auto;
      }

      .message {
        color: $primary-color;
        letter-spacing: 1px;
        font-style: italic;
      }
    }
  }
}
