@import '../variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$secondary-color: map_get($base-colors, 'text-color');
$light_gray: map_get($font-colors, 'light-gray');
$dark_gray: map_get($font-colors, 'dark-gray');
$white: map_get($font-colors, 'white');
$alto: map_get($font-colors, 'alto');
$lightning-yellow: map_get($font-colors, 'lightning-yellow');

.welcome-info {
  margin-top: 80px;

  .welcome-grid-container {
    width: 80%;
    margin: 0 auto;

    button {
      background-color: #fdc312;
      color: white;
      &:hover {
        background-color: #3c4461;
      }
    }

    h1 {
      color: $primary-color;
      text-transform: capitalize;

      &:after {
        content: '';
        display: block;
        width: 35%;
        padding-top: 10px;
        border-bottom: 2px solid $secondary-color;
      }
    }

    p {
      color: $primary-color;
    }

    .secondary-text {
      color: $secondary-color;
    }

    .details {
      .authorized-partner {
        background-color: #fff;
        box-shadow: 0 2px 14px 5px rgba(20, 20, 20, 0.05);
        padding: 0 8px;
        border-radius: 5px;
        margin-top: 25px;

        .grid-item {
          display: flex;
          align-items: center;
          justify-content: center;

          strong {
            font-size: 14px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    button {
      background-color: transparent;
      text-decoration: none;
      color: $primary-color;
      border: 2px solid $secondary-color;
      border-radius: 0;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $primary-color;
        color: $white;
        border: 2px solid $white;
        transition: 0.5s ease;
      }

      span {
        font-weight: bold;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.distributor-image {
  width: 100%;
  height: auto;
}

@media (min-width: 280px) and (max-width: 480px) {
  .welcome-info {
    margin-top: 0;

    .welcome-grid-container {
      .details {
        .authorized-partner {
          .grid-item {
            img {
              width: 60%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 900px) {
  .welcome-info {
    .welcome-grid-container {
      .details {
        .authorized-partner {
          .grid-item {
            img {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
